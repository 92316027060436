import { Formik } from 'formik';
import { encode } from 'querystring';
import React, { FC } from 'react';
import styled from 'styled-components';
import Body from '../components/atoms/Body';
import Box from '../components/atoms/Box';
import ContentWrapper from '../components/atoms/ContentWrapper';
import Flex from '../components/atoms/Flex';
import Mail from '../components/atoms/Mail';
import Map from '../components/atoms/Map';
import Phone from '../components/atoms/Phone';
import Stack from '../components/atoms/Stack';
import { ContactFormContent, detailsValidation } from '../components/ContactForm';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const contact: FC = () => {
    return (
        <>
            <SEO
                title="Contact opnemen"
                description="Verkoop Uw Woning is via meerdere wegen bereikbaar. Zo is het mogelijk om ons een mail te sturen, via instagram en gewoon een belletje"
            />
            <Layout whiteBackground>
                <StyledContentWrapper>
                    <FormWrapper>
                        <Flex flexDirection={['column', null, 'row']} mt={[4, null, 0]} mb={[4, null, 7]}>
                            <Box flex={1} mr={[null, null, 4]} mb={[4, null, 0]}>
                                <Body style={{ maxWidth: '28rem' }}>
                                    Mocht u vragen hebben of bent u benieuwd wat wij kunnen betekenen voor u? Neem dan
                                    contact met ons op via onderstaand contact formulier of middels een van onze
                                    contactgegevens.
                                </Body>
                            </Box>
                            <Flex flexDirection="column" flex={1}>
                                <Stack spacing={4}>
                                    <Flex alignItems="center">
                                        <Mail />
                                        <a href="mailto:info@verkoopuwwoning.nl">info@verkoopuwwoning.nl</a>
                                    </Flex>
                                    <Flex alignItems="center">
                                        <Phone />
                                        <a href="tel:+31202101070">020-2101070</a>
                                    </Flex>
                                    <Flex alignItems="center">
                                        <Map />
                                        <a
                                            href="https://g.page/Verkoopuwwoning?share"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Admiraal de Ruyterweg 135 <br /> 1056 EX Amsterdam
                                        </a>
                                    </Flex>
                                </Stack>
                            </Flex>
                        </Flex>
                        <Formik
                            initialValues={{
                                name: '',
                                email: '',
                                phone: '',
                                comment: '',
                            }}
                            onSubmit={async (values, { setSubmitting, setFieldError, resetForm }) => {
                                try {
                                    await fetch('/?no-cache=1', {
                                        method: 'POST',
                                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                                        body: encode({
                                            'form-name': 'contact',
                                            ...values,
                                        }),
                                    });

                                    alert('Verstuurd!');
                                    setTimeout(() => resetForm(), 200);
                                } catch (error) {
                                    setFieldError('name', 'Er is iets misgegaan. Probeer het later opnieuw.');
                                }

                                setSubmitting(false);
                            }}
                            validationSchema={detailsValidation}
                            validateOnMount
                        >
                            {({ handleReset, handleSubmit }) => (
                                <ContactFormContent resetHandler={handleReset} submitHandler={handleSubmit} />
                            )}
                        </Formik>
                    </FormWrapper>
                </StyledContentWrapper>
            </Layout>
        </>
    );
};

export default contact;

const FormWrapper = styled.div`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        width: 70vw;
    }
    max-width: 100rem;
    width: 100%;
    margin: auto;

    svg {
        width: 1.4rem;
        height: 1.4rem;
        margin-right: 0.8rem;
    }

    && {
        color: ${({ theme }) => theme.colors.neutral[1]};
    }
`;

const StyledContentWrapper = styled(ContentWrapper)`
    display: flex;
    /* background: ${({ theme }) => theme.colors.primary[3]}; */
    /* padding-top: 3.2rem; */
    padding-bottom: 3.2rem;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        padding-top: 10vh;
        padding-bottom: 10rem;

    }
`;
